export const queryCommitment = `query commitment($query: CommitmentQueryInput){
  commitment(query: $query) {
    _id
    title
    activity
    behaviour {
      name
      type
      answer
    }
    confirmation {
      _id
    }
    assignee {
      name
    }
    supervisor {
      name
    }
  }
}
`

export const getCommitments = `query commitments($query: CommitmentQueryInput, $limit: Int, $sortBy: CommitmentSortByInput){
  commitments(query: $query, limit: $limit, sortBy: $sortBy) {
    _id
    title
  }
}`

export const addCommitment = `mutation insertOneCommitment($data: CommitmentInsertInput!){
  insertOneCommitment(data: $data) {
    _id
  }
}`

export const updateCommitment = `mutation updateOneCommitment($query: CommitmentQueryInput, $data: CommitmentUpdateInput!) {
  updateOneCommitment(query: $query, set: $data) {
    _id,
  }
}
`

export const queryCommitmentReport = `query CommitmentReport($input: CommitmentReport!){
  CommitmentReport(input: $input) {
    commitments {
      _id
      name
      totalScore
      totalTotal
      averageAdherence
      confirmationsData {
        _id
        processId
        processCode
        processName
        score
        total
        activities {
          name
          behaviours {
            answer
            name
            type
            expectedAnswer
          }
        }
        cases {
          _id
          dueDate
          createdAt
          stage
          activity
          behaviour {
            answer
            name
            type
          }
        }
      }
    }
  } 
}
`